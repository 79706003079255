import { Component, ViewChild, ElementRef } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { ApiService } from '../../../../SGRE-shared/services/api.service';

declare let bootstrap: any;

@Component({
  selector: 'app-cart-proceed-checkout',
  templateUrl: './cart-proceed-checkout.component.html',
  styleUrls: ['./cart-proceed-checkout.component.scss']
})
export class CartProceedCheckoutComponent {

  @ViewChild('cartModal') cartModal: ElementRef;
  public cartsList: Observable<any>;
  public modalInstance: any;
  public currentTotal: any;
  public minimumOrderAmount: any;
  public cartCode: string;
  private unsubscribe$ = new Subject<void>();
  isNavigating: boolean;
  public currency:string;

  constructor(
    private cartsService: CartService,
    private router: Router,
    private globalService: GlobalService,
    private storageService: StorageService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.cartsList = this.cartsService.data$;
    localStorage.removeItem(AppConstants.SessionStorageKeys.cartCode)
    localStorage.removeItem(AppConstants.SessionStorageKeys.secondaryCartCode)
  }

  calculateTotal(carts: any): number {
    return carts.reduce((sum: number, cart: any) => sum + cart.totalPrice.value, 0);
  }

  getMinOrderFromActiveCart() {
    let activecart = this.storageService.activeCart;
    let isQuotation = activecart.entries.every(cart => cart.isQuotation)
    return isQuotation ? 0 : +activecart.legalEntity.minOrderAmount
  }

  onProceed(): void {
    if (this.isNavigating) {
      return;
    }
    this.cartsList.pipe(take(1)).subscribe(carts => {
      this.currency = this.findSymbol(carts?.at(0).totalPrice?.currencyIso);
       this.currentTotal = this.calculateTotal(carts);
      this.minimumOrderAmount = this.getMinOrderFromActiveCart();
      if (this.currentTotal < this.minimumOrderAmount) {
        this.modalInstance = new bootstrap.Modal(this.cartModal.nativeElement);
        this.modalInstance.show();
        return;
      }
      this.globalService.loadingSubject.next(true);
      this.cartsService.postToCheckout(this.storageService.cartId)
        .pipe(take(1), takeUntil(this.unsubscribe$))
        .subscribe((response: any) => {
          const responseBody = response.body;
          if (responseBody && responseBody.carts && Array.isArray(responseBody.carts)) {
            if (responseBody.carts.length > 0) {
              responseBody.carts.forEach(cart => {
                const hasQuotation = cart.entries.some(entry => entry.isQuotation);
                if (hasQuotation)
                  this.storageService.secondaryCartCode = cart.code;
                else
                  this.storageService.cartCode = cart.code;
              });
              this.apiService.cartGroupId = responseBody.cartGroupId;
              this.storageService.checkoutCartGroupId = responseBody.cartGroupId;
              this.isNavigating = true;
              this.router.navigate([AppConstants.routeUrls.checkout], {
                queryParams: { cartGroupId: responseBody.cartGroupId }
              }).then(() => {
                this.isNavigating = false;
              });
            }
          } else {
            this.globalService.loadingSubject.next(false);
          }
        }, (error) => {
          this.globalService.loadingSubject.next(false);
        });
    });
  }

  navigateToProducts(): void {
    if (this.modalInstance) {
      this.modalInstance.hide();
      this.removeBackdrop();
    }
    this.router.navigate([AppConstants.routeUrls.plp]);
  }

  private removeBackdrop(): void {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
  }

  findSymbol(currency) {
    let currencies = ['EUR', 'DKK', 'USD', 'GBP', 'CAD'];
    let symbols = ['€', 'DKK', '$', 'GBP', 'CAD'];
    return symbols.at(currencies.indexOf(currency));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}