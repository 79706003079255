<div class="mb-3">
	<div *ngIf="cartsList | async as carts">
		<button *ngIf="carts?.length > 0" (click)="onProceed()" class="btn cstm-btn-primary mr-3 mt-2">
			{{"cartPage.proceed" | cxTranslate}}
		</button>
		<button (click)="navigateToProducts()" class="btn cstm-btn-light mr-3 mt-2 cart-continue-shop-btn">
			{{"cartPage.continueShopping" |cxTranslate}}
		</button>
	</div>
</div>
<div #cartModal class="modal fade" tabindex="-1" id="cartModal" aria-labelledby="cartModal" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"cartPage.orderAmountToLow" |cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>
					{{"cartPage.yourCurrentTotal" |cxTranslate}} {{currentTotal | number:'1.0-0'}} {{currency}}
					but order less than
					{{minimumOrderAmount | number:'1.0-0'}} {{currency}}
					can't be placed.{{"cartPage.PleaseAddMore" |cxTranslate}}
				</p>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button type="button" class="btn cstm-btn-light mb-2" data-dismiss="modal">
					{{"cartPage.close" |cxTranslate}}
				</button>
				<button (click)="navigateToProducts()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"cartPage.continueShopping" |cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>